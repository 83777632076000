import React from "react"
import Box from "reusecore/Box"
import LegalWrapper from "./legal.style"
import { Container } from "reusecore/Layout"

const PrivacyPolicyBody = () => {
  return (
    <LegalWrapper>
      <Box>
        <Container>
          <h1>Privacy Policy</h1>
          <p>Effective date: 2022-02-22</p>
          <p>Updated on: 2022-02-22</p>
          <p>
            This Privacy Policy explains the policies of Work 'n Skill on the collection
            and use of the information we collect when you access https://www.worknskill.com (the “Service”). This Privacy Policy describes your
            privacy rights and how you are protected under privacy laws.</p>
          <p>
            By using our Service, you are consenting to the collection and use of your information in accordance
            with this Privacy Policy. Please do not access or use our Service if you do not consent to the collection
            and use of your information as outlined in this Privacy Policy.</p>
          <p>Work 'n Skill is authorized to modify this Privacy Policy at any time.
            This may occur without prior notice.</p>
          <p>
            Work 'n Skill will post the revised Privacy Policy on the https://www.worknskill.com website</p>

          <h3>Collection and Use of Your Personal Information</h3>
          <h4>Information We Collect</h4>
          <p>
            When using our Service, you will be prompted to provide us with personal information used to
            contact or identify you. https://www.worknskill.com collects the following information:    </p>

          <ul>
            <li>Usage Data</li>
            <li>Email</li>
          </ul>

          <p>Usage Data includes the following:</p>
          <ul>
            <li>Internet Protocol (IP) address of computers accessing the site</li>
            <li>Web page requests</li>
            <li>Referring web pages</li>
            <li>Browser used to access site</li>
            <li>Time and date of access</li>
          </ul>

          <h4>How We Collect Information</h4>
          <p>
            https://www.worknskill.com collects and receives information from you in the following manner:    </p>
          <ul>
            <li>Newsletter form</li>
          </ul>
          <p>
            Your information will be stored for up to 356 days after it is
            no longer required to provide you the services. Your information may be retained for longer periods for
            reporting or record- keeping in accordance with applicable laws. Information which does not identify you
            personally may be stored indefinitely.    </p>

          <h4>How We Use Your Information</h4>
          <p>
            https://www.worknskill.com may use your information for the following purposes:        </p>
          <ul>
            <li><b>To contact you.</b> Work 'n Skill will contact you by email, phone, SMS, or another form of
              electronic communication related to the functions, products, services, or security updates when
              necessary or reasonable.</li>
            <li><b>Marketing and promotional initiatives.</b> Work 'n Skill will use non-specific information gathered
              from you in order to improve our marketing efforts.</li>
            <li><b>To update you</b> with news, general information, special offers, new services, and events.</li>
          </ul>


          <h4>Third-party Sharing</h4>
          <p>
            Any third party we share your information with must disclose the purpose for which they intend to use
            your information. They must retain your information only for the duration disclosed when requesting or
            receiving said information. The third-party service provider must not further collect, sell, or use your
            personal information except as necessary to perform the specified purpose.        </p>
          <p>Your information may be shared to a third-party for reasons including:</p>
          <ul>
            <li><b>Analytics information.</b> Your information might be shared with online
              analytics tools in order to track and analyse website traffic.</li>
          </ul>
          <p>
            If you choose to provide such information during registration or
            otherwise, you are giving Work 'n Skill permission to use, share, and store that information in a manner consistent with
            this Privacy Policy.        </p>

          <p>Your information may be disclosed for additional reasons, including:</p>
          <ul>
            <li>Complying with applicable laws, regulations, or court orders.</li>
            <li>Responding to claims that your use of our Service violates third-party rights.</li>
            <li>Enforcing agreements you make with us, including this Privacy Policy.</li>
          </ul>

          <h4>Cookies</h4>
          <p>
            Cookies are small text files that are placed on your computer by websites that you visit. Websites use
            cookies to help users navigate efficiently and perform certain functions. Cookies that are required for
            the website to operate properly are allowed to be set without your permission. All other cookies need
            to be approved before they can be set in the browser.    </p>
          <ul>
            <li><b>Strictly necessary cookies.</b> Strictly necessary cookies allow core website functionality such as
              user login and account management. The website cannot be used properly without strictly necessary cookies.</li>
          </ul>


          <h4>Security</h4>
          <p>
            Your information’s security is important to us. https://www.worknskill.com
            utilizes a range of security measures to prevent the misuse, loss, or alteration of the information you have given us.
            However, because we cannot guarantee the security of the information you provide us, you must access our service at your
            own risk.    </p>
          <p>
            Work 'n Skill is not responsible for the performance of websites
            operated by third parties or your interactions with them. When you leave this website, we recommend you review the
            privacy practices of other websites you interact with and determine the adequacy of those practices.    </p>

          <h4>Contact Us</h4>
          <p>For any questions, please contact us through the following methods:</p>
          <p>Name: Work 'n Skill</p>
          <p>Email: office@worknskill.com</p>
          <p>Website: https://www.worknskill.com</p>

        </Container>
      </Box>
    </LegalWrapper>
  )
}

export default PrivacyPolicyBody;
