import React from "react"
import { ThemeProvider } from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Navigation from "sections/Navigation";
import Footer from "sections/Footer";
import theme from "assets/theme/theme";
import GlobalStyle from "assets/theme";
import PrivacyPolicyBody from "../sections/PrivacyPolicy";

const PrivacyPolicy = () => (
    <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Layout>
      <SEO title="Work 'n Skill - Privacy Policy" />
      <Navigation />
      <PrivacyPolicyBody />
      <Footer />
    </Layout>
  </ThemeProvider>
)

export default PrivacyPolicy
